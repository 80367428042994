import React from "react";
import { Navbar } from "react-bootstrap";

import "./NavigationBar.css";

const NavigationBar = () => {
  return (
    <div className="navigation-bar">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Navbar expand="lg">
              <Navbar.Brand href="#home">
                <h1 style={{ color: "green" }}>Startupedia</h1>
              </Navbar.Brand>
            </Navbar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
