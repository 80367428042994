import React from 'react'
import Banner from '../Banner/Banner';
import NavigationBar from '../NavigationBar/NavigationBar';
import './Header.css';

export default function Header() {
  return (
    <div className="header-area">
            <NavigationBar />
            <Banner />
        </div>
  )
}




