import React, { useState } from "react";
import "./Footer.css";
// import emailjs from "@emailjs/browser";

const Footer = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  //const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //setLoading(true);

    alert("Thank you. We will get back to you as soon as possible. ");

    // emailjs
    //   .send(
    //     // "service_us5yov8",
    //     // "template_69lghvk",
    //     {
    //       from_name: form.name,
    //       to_name: "Ronak Jilka",
    //       from_email: form.email,
    //       to_email: "ronakjilka@gmail.com",
    //       message: form.message,
    //     }
    //     // "G5lG0HhbBUkqvQmzo"
    //   )
    //   .then(
    //     () => {
    //       setLoading(false);
    //       alert("Thank you. I will get back to you as soon as possible.");

    //       setForm({
    //         name: "",
    //         email: "",
    //         message: "",
    //       });
    //     },
    //     (error) => {
    //       setLoading(false);
    //       console.error(error);

    //       alert("Ahh, something went wrong. Please try again.");
    //     }
    //   );
  };

  return (
    <div className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="footer-text">
              <h2 className="mb-4">
                Let us handle your project, professionally.
              </h2>
              <p>
                With well written codes, we build amazing apps for all
                platforms, mobile and web apps in general.
              </p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="footer-form">
              <form action="" onSubmit={handleSubmit}>
                <input
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  placeholder="Your email address"
                />

                {/* <input type="text" placeholder="Your name/company’s name" /> */}
                <input
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  placeholder="Your name/company’s name"
                />
                <textarea
                  rows={7}
                  name="message"
                  value={form.message}
                  onChange={handleChange}
                  placeholder="Your message"
                />

                <button type="submit">{"Send"}</button>
                {/* {loading ? "Sending..." : "Send"} */}
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-10">
            <div className="copyright-area text-center">
              <p className="mb-0 mt-5 pt-3">
                Copyright By Startupedia &copy; {new Date().getFullYear()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
