import React from "react";
import banner from "../../assets/Frame.png";
import "./Banner.css";
import { NavLink } from "react-bootstrap";
export default function Banner() {
  return (
    <div className="banner-area mt-sm-4 mt-md-5">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="banner-text mt-lg-4">
              <h2>Let’s Grow Your Brand To The Next Level</h2>
              <p></p>
              <NavLink to="">Hire Us</NavLink>
            </div>
          </div>
          <div className="offset-xl-1 col-xl-6 col-lg-6">
            <div >
              <img style={{width:"100%",height: "auto"}}src={banner} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
