import React from 'react'
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

export default function Home() {
  return (
    <div className="home">
    <Header />
 
    <Footer />
</div>
  )
}
