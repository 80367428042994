import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./components/Home/Home";

function App() {
  return (
    <div>
      <Home></Home>
    </div>
  );
}

export default App;
